export default function detectBrowserLanguage() {
  if (typeof window === 'undefined') {
    return null
  }

  // Get a valid language code, e.g. `de`, `de-CH` etc.
  const locale = (navigator.language || navigator.userLanguage).toLowerCase()

  if (!locale.includes('-')) {
    return locale
  }

  return locale.split('-')[0]
}
